import CurrencyRupee from '@mui/icons-material/CurrencyRupee';
import { Box } from '@mui/system';

export const rupeeDecimalFormatter = (
  value: number | null | undefined,
  fontSize = '0.8rem'
) => {
  let amount = value && value.toString();
  if (!amount) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CurrencyRupee sx={{ fontSize }} />0
      </Box>
    );
  }
  let afterPoint = '';
  if (amount?.indexOf('.') > 0)
    afterPoint = amount
      ?.substring(amount?.indexOf('.'), amount?.length)
      .slice(0, 3);
  amount = Math.floor(+amount).toString();
  let lastThree = amount.substring(amount?.length - 3);
  const otherNumbers = amount.substring(0, amount?.length - 3);
  if (otherNumbers !== '') lastThree = ',' + lastThree;
  const res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.25,
      }}
    >
      <CurrencyRupee sx={{ fontSize }} />
      {res}
    </Box>
  );
};

export const getDMY = (dateStr: Date) => {
  const dateObj = new Date(dateStr);
  const date = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return `${date}/${month}/${year}`;
};

export const integerToWordFormatterLabels = (value: number) => {
  if (value === 0) {
    return '0';
  }
  if (value >= 1e7) {
    // Crore
    return (value / 1e7).toFixed(2) + ' Crore';
  } else if (value >= 1e5) {
    // Lakh
    return (value / 1e5).toFixed(2) + ' Lakh';
  } else if (value >= 1e3) {
    // Thousand
    return (value / 1e3).toFixed(2) + 'K';
  }
  return value.toString();
};
