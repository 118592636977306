import { withPageInternals } from 'src/hoc';
import { Box } from '@mui/system';
import {
  Card,
  Dialog,
  FetchingSkeleton,
  Flex,
  Table,
  Typography,
  BuyerLogo,
  DateRange,
} from 'src/components';
import { COLORS } from 'src/theme';
import {
  getSupplierId,
  useAppSelector,
  useFetchInvoiceSummaryQuery,
  useFetchPaymentHistoryQuery,
} from 'src/redux';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ButtonBase, Popover } from '@mui/material';
import { useState } from 'react';
import Indicators from './Indicators';
import {
  rupeeDecimalFormatter,
  INVOICES,
  integerToWordFormatterLabels,
} from 'src/utils';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import { ListOfPaymentHistoryType } from 'src/types';
import { TableColumnType } from 'src/components/common/Table';
import { MultipleInvoices } from 'src/components/Modals';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { alpha, useTheme } from '@mui/material';
import { PAYMENT_STATUS } from 'src/constants';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TotalLimit from 'src/assets/images/total-limit.svg';
import UtilizedLimit from 'src/assets/images/utilized-limit.svg';
import AvailableLimit from 'src/assets/images/available-limit.svg';
import { DateRangeOnChange } from 'src/components/common/DateRange';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Home = () => {
  const navigate = useNavigate();
  const supplierId = useAppSelector(getSupplierId);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchBy, setSearchBy] = useState('latestTransactionDate');
  const { palette, spacing } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [date, setDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const [multipleInvoiceData, setMultipleInvoiceData] = useState<{
    isVisible: boolean;
    data: ListOfPaymentHistoryType;
  }>({
    isVisible: false,
    data: {
      paymentDate: '',
      utrNumber: '',
      paymentAmount: 0,
      partyName: '',
      listOfInvNumber: [],
      paymentType: '',
    },
  });

  const { data: paymentHistoryData, isFetching: ispaymentHistoryDataFetching } =
    useFetchPaymentHistoryQuery({
      pageNumber,
      supplierId,
      from: date.startDate ? date.startDate : '',
      to: date.endDate ? date.endDate : '',
      searchValue: '',
      searchBy,
    });

  const { data: invoiceSummaryData, isFetching: isInvoiceSummaryFetching } =
    useFetchInvoiceSummaryQuery({
      supplierId,
    });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns: TableColumnType<ListOfPaymentHistoryType>[] = [
    {
      label: 'Party Name',
      id: 'partyName',
      render: ({ row }) => {
        return (
          <Typography fontSize="0.8rem">
            {row?.partyName ? (
              <Flex className="gap-2" alignItems={'center'}>
                <BuyerLogo name={row?.partyName} />
                {row?.partyName}
              </Flex>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },
    {
      label: 'Invoice Number',
      id: 'listOfInvNumber',
      render: ({ row }) => {
        const { listOfInvNumber } = row;
        const isMultipleInvoices = listOfInvNumber?.length > 1;
        return (
          <Flex
            className="gap-2 cursor-pointer"
            onClick={() => onMultipleInvoiceClick(row)}
          >
            <DescriptionOutlinedIcon
              sx={{
                color: COLORS.PRIMARY,
                backgroundColor: alpha(palette.primary.main, 0.2),
                borderRadius: '0.1rem',
              }}
              fontSize="medium"
            />
            <Typography
              color="primary"
              fontWeight="bold"
              className="max-w-[160px] truncate"
              fontSize="0.8rem"
            >
              {listOfInvNumber?.[0]}
            </Typography>
            {isMultipleInvoices && (
              <Typography color="primary" fontWeight="bold">
                +{listOfInvNumber?.length - 1}
              </Typography>
            )}
          </Flex>
        );
      },
    },
    {
      label: 'Payment type',
      id: 'paymentType',
      render: ({ value }) => {
        return <div className="capitalize">{value}</div>;
      },
    },
    {
      label: 'Payment amount',
      id: 'paymentAmount',
      render: ({ value }) => {
        if (typeof value === 'number') {
          return <div>{rupeeDecimalFormatter(value)}</div>;
        }
        return <div>{value}</div>;
      },
    },
    {
      label: 'Payment Date',
      id: 'paymentDate',
    },
    {
      label: 'UTR',
      id: 'utrNumber',
      render: ({ row }) => {
        const { palette } = useTheme();
        return (
          <>
            <div>{row?.utrNumber || '-'}</div>
            <Flex className="gap-2" alignItems={'center'}>
              {row?.status === PAYMENT_STATUS.PROCESSED ? (
                <>
                  <CheckCircleIcon
                    fontSize="medium"
                    sx={{
                      color: palette.lightGreen,
                    }}
                  />
                  <div>Processed</div>
                </>
              ) : row?.status === PAYMENT_STATUS.PROCESSING ? (
                <>
                  <AccessTimeFilledIcon
                    fontSize="medium"
                    sx={{
                      color: palette.orange,
                    }}
                  />
                  <div>Processing</div>
                </>
              ) : null}
            </Flex>
          </>
        );
      },
    },
  ];
  const getDate = () => {
    const { startDate, endDate } = date;
    const formattedStartDate = startDate ? startDate : '';
    const formattedEndDate = endDate ? endDate : '';
    if (formattedStartDate === formattedEndDate) {
      return formattedStartDate;
    } else {
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
  };

  const resetFilters = () => {
    setDate({
      startDate: null,
      endDate: null,
    });
    setSearchBy('latestTransactionDate');
  };

  const onPageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const onViewAllClick = () => {
    navigate(INVOICES);
  };

  const onMultipleInvoiceClick = (row: ListOfPaymentHistoryType) => {
    setMultipleInvoiceData({
      isVisible: true,
      data: row,
    });
  };

  const handleDateChange = ({ startDate, endDate }: DateRangeOnChange) => {
    setDate({
      startDate,
      endDate,
    });
    setPageNumber(1);
    setSearchBy('');
  };

  const resetMultipleInvoice = () => {
    setMultipleInvoiceData({
      isVisible: false,
      data: {
        paymentDate: '',
        utrNumber: '',
        paymentAmount: 0,
        partyName: '',
        listOfInvNumber: [],
        paymentType: '',
      },
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-popover' : undefined;

  return (
    <Box className="flex flex-col gap-6">
      <Card>
        <Typography fontSize="22px" fontWeight="bold">
          Limit Usage
        </Typography>
        {isInvoiceSummaryFetching ? (
          <FetchingSkeleton />
        ) : (
          <Box className="grid grid-cols-5 mt-6 h-[50px]">
            <Box className="col-span-1 ">
              <Flex alignItems={'center'}>
                <Box className="mx-2">
                  <img src={TotalLimit} />
                </Box>
                <Box>
                  <Typography>Total Limit</Typography>
                  <Typography fontWeight="bold">
                    {rupeeDecimalFormatter(invoiceSummaryData?.totalLimit)}
                  </Typography>
                </Box>
              </Flex>
            </Box>
            <Box className="col-span-1 ">
              <Flex alignItems={'center'}>
                <Box className="mx-2">
                  <img src={UtilizedLimit} />
                </Box>
                <Box>
                  <Typography>Total Utilized Limit</Typography>
                  <Typography fontWeight="bold">
                    {rupeeDecimalFormatter(invoiceSummaryData?.utilizedLimit)}
                  </Typography>
                </Box>
              </Flex>
            </Box>
            <Box className="col-span-1 ">
              <Flex alignItems={'center'}>
                <Box className="mx-2">
                  <img src={AvailableLimit} />
                </Box>
                <Box>
                  <Typography>Available Limit</Typography>
                  <Typography fontWeight="bold">
                    {rupeeDecimalFormatter(invoiceSummaryData?.availableLimit)}
                  </Typography>
                </Box>
              </Flex>
            </Box>
          </Box>
        )}
      </Card>
      <Box className="grid grid-cols-2 gap-6">
        <Card>
          <Flex
            justifyContent={'space-between'}
            sx={{ marginBottom: spacing(1) }}
          >
            <Typography fontSize="22px" fontWeight="bold">
              Summary
            </Typography>
            <div aria-describedby={id} onClick={handleClick}>
              <InfoOutlinedIcon className="cursor-pointer" />
            </div>
          </Flex>
          {isInvoiceSummaryFetching ? (
            <FetchingSkeleton />
          ) : (
            <Bar
              data={{
                labels: ['Total', 'Paid', 'Outstanding', 'Overdue'],
                datasets: [
                  {
                    label: 'Invoice Amount',
                    data: [
                      invoiceSummaryData?.totalInvoicesAmount,
                      invoiceSummaryData?.paidInvoicesAmount,
                      invoiceSummaryData?.outstandingInvoicesAmount,
                      invoiceSummaryData?.overdueInvoicesAmount,
                    ],
                    backgroundColor: [
                      alpha(palette.primary.main, 0.8),
                      alpha(palette.darkGreen, 0.8),
                      alpha(palette.pink, 0.8),
                      alpha(palette.orange, 0.8),
                    ],
                    barThickness: 58,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    backgroundColor: alpha(palette.white, 0.9),
                    titleColor: COLORS.BLACK,
                    bodyColor: COLORS.BLACK,
                    borderColor: COLORS.BLACK,
                  },
                  datalabels: {
                    anchor: 'end',
                    align: 'top',

                    formatter: (val) => {
                      return integerToWordFormatterLabels(val);
                    },
                    font: {
                      size: 14,
                    },
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                    },
                  },
                },
                layout: {
                  padding: {
                    top: 20,
                  },
                },
              }}
              height={180}
            />
          )}
        </Card>
        <Card>
          <Typography fontSize="22px" fontWeight="bold">
            Invoices Details
          </Typography>
          {isInvoiceSummaryFetching ? (
            <FetchingSkeleton />
          ) : (
            <>
              <Box
                className="grid grid-cols-4 py-2 mt-4"
                sx={{
                  background: COLORS.PRIMARY,
                  color: COLORS.WHITE,
                }}
              >
                <Box className="col-span-1" />
                <Typography color="white">Party</Typography>
                <Typography color="white">Invoices</Typography>
                <Typography color="white">Amount</Typography>
              </Box>
              <Box className="grid grid-cols-4 py-2">
                <Indicators color={'INDIGO'} indicatorText="Total" />
                <Box>{invoiceSummaryData?.totalParties}</Box>
                <Box>{invoiceSummaryData?.totalInvoices}</Box>
                <Box>
                  {rupeeDecimalFormatter(
                    invoiceSummaryData?.totalInvoicesAmount
                  )}
                </Box>
              </Box>
              <Box className="grid grid-cols-4 py-2">
                <Indicators color={'DARK_GREEN'} indicatorText="Paid" />
                <Box>{invoiceSummaryData?.paidParties}</Box>
                <Box>{invoiceSummaryData?.paidInvoices}</Box>
                <Box>
                  {rupeeDecimalFormatter(
                    invoiceSummaryData?.paidInvoicesAmount
                  )}
                </Box>
              </Box>
              <Box className="grid grid-cols-4 py-2">
                <Indicators color={'PINK'} indicatorText="Outstanding" />
                <Box>{invoiceSummaryData?.outstandingParties}</Box>
                <Box>{invoiceSummaryData?.outstandingInvoices}</Box>
                <Box>
                  {rupeeDecimalFormatter(
                    invoiceSummaryData?.outstandingInvoicesAmount
                  )}
                </Box>
              </Box>
              <Box className="grid grid-cols-4 py-2">
                <Indicators color={'ORANGE'} indicatorText="Overdue" />
                <Box>{invoiceSummaryData?.overdueParties}</Box>
                <Box>{invoiceSummaryData?.overdueInvoices}</Box>
                <Box>
                  {rupeeDecimalFormatter(
                    invoiceSummaryData?.overdueInvoicesAmount
                  )}
                </Box>
              </Box>
            </>
          )}
        </Card>
      </Box>
      <Card>
        <Flex justifyContent={'space-between'}>
          <Flex className="gap-4">
            <Typography fontSize="22px" fontWeight="bold" color="green">
              <>
                Collections on {getDate()}
                {searchBy === 'latestTransactionDate'
                  ? paymentHistoryData?.listOfPaymentHistory[0]?.paymentDate
                  : null}
              </>
            </Typography>

            <DateRange
              startDate={date.startDate}
              endDate={date.endDate}
              onChange={handleDateChange}
            />
            <Box
              sx={{
                borderRadius: 5,
                p: `${spacing(0.5)} ${spacing(1.5)}`,
                mr: spacing(1.2),
                fontSize: '1rem',
                fontWeight: 'bold',
                color: palette.primary.main,
                backgroundColor: alpha(palette.primary.main, 0.2),
                cursor: 'pointer',
              }}
              onClick={resetFilters}
            >
              Reset Filter
            </Box>
          </Flex>
          <ButtonBase onClick={onViewAllClick}>
            <Typography
              color="primary"
              fontWeight="bold"
              fontSize="0.7rem"
              align="center"
            >
              View All
            </Typography>
          </ButtonBase>
        </Flex>
        {ispaymentHistoryDataFetching ? (
          <FetchingSkeleton />
        ) : (
          <>
            <Box className="mt-4 mb-1">
              <Typography color="gray" fontWeight="bold">
                Payment Received:
              </Typography>
            </Box>
            <Typography fontWeight="bold" className="mb-4">
              {rupeeDecimalFormatter(paymentHistoryData?.paymentRecieved)}
            </Typography>
            <Table
              rows={paymentHistoryData?.listOfPaymentHistory || []}
              columns={columns}
              className="mt-4"
              paginationComponent={
                <Pagination
                  count={paymentHistoryData?.totalPage}
                  page={pageNumber}
                  onChange={onPageChange}
                />
              }
            />
          </>
        )}
      </Card>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          left: -470,
        }}
      >
        <Box className="p-4 space-y-4">
          <Box className="flex items-center gap-2">
            <Indicators color={'INDIGO'} indicatorText="Total:" textBold />
            <Typography>Invoice amount requested</Typography>
          </Box>
          <Box className="flex items-center gap-2">
            <Indicators color={'DARK_GREEN'} indicatorText="Paid:" textBold />
            <Typography>Amount paid by Buyer till date</Typography>
          </Box>
          <Box className="flex items-center gap-2">
            <Indicators color={'PINK'} indicatorText="Outstanding:" textBold />
            <Typography>
              Sum of gross outstanding of all buyers of a supplier
            </Typography>
          </Box>
          <Box className="flex items-center gap-2">
            <Indicators color={'ORANGE'} indicatorText="Overdue:" textBold />
            <Typography>Amount outstanding in overdue</Typography>
          </Box>
        </Box>
      </Popover>
      <Dialog
        open={multipleInvoiceData.isVisible}
        onClose={resetMultipleInvoice}
        heading="Payment Details"
        maxWidth="lg"
      >
        <MultipleInvoices invoiceData={multipleInvoiceData.data} />
      </Dialog>
    </Box>
  );
};

export default withPageInternals(Home);
